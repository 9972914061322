<!-- 美容师首页 -->
<template>
  <div>
    <div class="main">
      <div style="font-size: 26px; font-weight: bold">我的美容师</div>
      <div>
        <!-- <div>
          <el-button
            type="primary"
            style="height: 34px; line-height: 10px; margin-right: 45px"
            class="cinput"
            @click='add'
            >+添加题目</el-button
          >
          <el-radio-group v-model="radio" style="height: 34px" class="cinput">
            <el-radio-button :label="item.id" v-for='(item,index) in qslist' :key='index'>{{item.name}}</el-radio-button>
          </el-radio-group>
        </div> -->
        <div>
          <el-select
            v-model="value"
            placeholder="等级筛选"
            class="cinput ac"
           
          >
            <el-option
              v-for="item in levelLists"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>

          <!-- <el-select
            v-model="storeId"
            placeholder="门店筛选"
            class="cinput ac"
           style="margin-left: 20px;"
          >
            <el-option
              v-for="item in storeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select> -->
          <!-- <div style="width: 221px">
            <el-input
              v-model="input"
              placeholder="请输入搜索内容"
              class="cinput ac"
              @keyup.enter.native="list"
            ></el-input>
            <div
              style="
                display: flex;
                height: 34px;
                width: 34px;
                align-items: center;
                background: #f7f7f7;
              "
            >
              <img
                v-if="isshow"
                src="../../images/search2.png"
                alt=""
                @click="list"
                style="background: #f7f7f7"
              />
              <img
                v-if="isshow == false"
                src="../../images/search1.png"
                alt=""
                style="background: #f7f7f7"
              />
            </div>
          </div> -->
        </div>
      </div>

      <el-table
        :header-cell-style="{ background: '#F7F7F7' }"
        :data="tableData"
        style="width: 100%; min-height: 600px"
         @selection-change="handleSelectionChange"
      >
      <el-table-column
      type="selection"
      align='center'
      width="55"/>
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column prop="userName" label="美容师名称" align="center" >
        </el-table-column>
        <el-table-column prop="levelName" align="center" label="美容师等级" :show-overflow-tooltip="true" >
        </el-table-column>
        <el-table-column prop="storeName" align="center" label="门店" :show-overflow-tooltip="true" >
        </el-table-column>
        <el-table-column prop="address" label="操作" align="center" >
          <template slot-scope="scope">
            <div style="display: flex; justify-content: center">
              <el-link type="primary" :underline="false" @click='details(scope.row)'>查看详情</el-link>

              <!-- <el-link type="primary" :underline="false" @click='dele(scope.row)' style="margin-left:31px">删除</el-link> -->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: flex-end;
        "
      >
        <!-- <el-button type="primary" @click='deleall' :disabled='isdisabled'>删除选中</el-button> -->
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageIndex"
          :page-sizes="[10, 15, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {questiontypeList,qsList,levelList,qssaveOrUpdate,beauticianList,storepageList} from "../../apis/index";
export default {
  data() {
    return {
      storeId:'2',
      storeList:[],
      pageIndex: 1,
      pageSize: 10,
      total:0,
      qslist:[],
      levelLists:[],
      value: "",
      isshow: false,
      isshow2: true,
      isdisabled:true,
      currentPage: 1,
    //   radio: "1",
      input: "",
      tableData: [
       
      ],
    };
  },
  watch: {
    input(newdata, olddata) {
     if(newdata==''){
        this.isshow=false
       this.input=''
       this.list()
     }else{
       this.isshow=true
     }
    },
    radio(newdata, olddata) {
     this.list()
    },
    value(){
      this.list()
    },
  },
  created(){
    this.questype()
    this.list()
    this.leave()
    // this.store()
  },
    beforeRouteLeave(to, from, next) {
    if(to.path=='/exercisesAdd'){//添加页，就不缓存
      this.$route.meta.keepAlive=false
      setTimeout(() => {
        next()
      }, 100);
    }else{//详情页缓存
     this.$route.meta.keepAlive=true
      setTimeout(() => {
        next()
      }, 100);
    }
    // 导航离开该组件的对应路由时调用
    // 可以访问组件实例 `this`
  },
  methods: {
     async store(){//门店列表
      const params={
        pageIndex:1,
        pageSize:999,
        // searchStr:this.input,
        // isValidity:1
      }
     const {data}=await storepageList(params)
     if(data.code==200){
       this.storeList=data.data.records
     }
    },
   async leave() {
      //获取等级列表
      const { data } = await levelList({roleId:3});
      if (data.code == 200) {
        this.levelLists = data.data;
      } else {
        this.$message.error(data.msg);
      }
    },
   async list(){
      const params={
        // questionTypeId:this.radio,
        // searchStr:this.input,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        levelId:this.value,
        storeId:this.storeId
      }
     const {data}=await beauticianList(params)
     if(data.code==200){
       this.tableData=data.data.records
       this.total=Number(data.data.total)
     }
    },
     async questype() {//题目类型列表
      const { data } = await questiontypeList();
      let qslist=data.data
      this.qslist = qslist;
    
    },
    add(){//添加题目
      this.$router.push({path:'/exercisesAdd'})
    },
    details(row){//查看详情
      this.$router.push({path:'/beauticiandetails',query:{row:JSON.stringify(row)}})
    },
    search() {
      console.log("我是搜索");
    },
     handleSelectionChange(val) {//选中
        console.log(val)
        if(val.length>0){
          this.isdisabled=false
        }else{
          this.isdisabled=true
        }
      },
    handleSizeChange(val) {
      this.pageSize=val
      console.log(`每页 ${val} 条`);
      this.list()
    },
    handleCurrentChange(val) {
      this.pageIndex=val
      console.log(`当前页: ${val}`);
      this.list()
    },
     dele(row) {
        const params={
         id:row.id,
         isValidity:0
       }
        this.$confirm('此操作将永久删除该项, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
           qssaveOrUpdate(params).then(res=>{
            if(res.data.code==200){
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.list()
            }else{
              this.$message({
                type: 'error',
                message: res.data.msg
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      },
       deleall() {
        this.$confirm('此操作将永久删除选中项, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      }
  },
};
</script>

<style lang='less' scoped>
.main {
  // min-height: 800px;
  background: #fff;
  margin: 19px 0;
  padding: 23px;
  border-radius: 14px;
}
.main > div:first-child {
  font-size: 26px;
  // font-weight: bold;
}
.main > div:nth-child(2) {
  display: flex;
  justify-content: space-between;
  height: 34px;
  margin: 18px 0;
  div {
    display: flex;
    img {
      width: 25px;
      height: 25px;
    }
  }
}
/deep/.el-radio-button__inner {
  height: 34px;
  line-height: 10px;
}
.cinput /deep/.el-input__inner {
  height: 34px;
  line-height: 10px;
}
/deep/.el-button--primary {
  height: 34px; line-height: 10px; 
}
/deep/.el-table::before {
  height: 0;
}
.ac /deep/.el-input__inner {
  border: none;
  background: #f7f7f7;
  // margin-right: 11px;
}
.red {
  color: #ff1b0b;
}
</style>
